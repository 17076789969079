import { Theme } from "theme-ui";

const theme: Theme = {
    breakpoints: ['600px', '960px', '1280px', '1720px'],
    colors: {
        text: '#4f4f4f',
        background: '#fff',
        highlight: '#49B49E',
        muted: '#07d',
        secondary: '#77e4a8',
        primary: '#172751'
    },
    fonts: {
        body: 'Montserrat, "Helvetica Neue", sans-serif',
        heading: 'Montserrat, "Helvetica Neue", sans-serif',        
    },
    fontSizes: [
        10, 12, 14, 16, 20, 24, 32, 48, 64
    ],
    fontWeights: {
        body: 300,
        heading: 300,
        bold: 700,
    }
}

export default theme